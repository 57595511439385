import FingerprintJS from "@fingerprintjs/fingerprintjs";
import QRCode from "qrcode";

document.addEventListener("DOMContentLoaded", async () => {
    let deviceFingerprintId;

    try {
        // Tente gerar o Fingerprint e capturar o ID
        deviceFingerprintId = await initializeFingerprintAndQRCode();
        console.log("fingerprint.js :: Remote Visitor ID: ", deviceFingerprintId);

        if (!deviceFingerprintId) {
            console.warn("Atenção: O 'deviceFingerprintId' está vazio ou indefinido.");
        }
    } catch (error) {
        console.error("Erro ao inicializar Fingerprint e QRCode:", error);
        return; // Encerra a execução se ocorrer um erro
    }

    setTimeout(async () => {
        try {
            const response = await axios.post('/check-user-device-link', {
                device_fingerprint_id: deviceFingerprintId,
            });

            if (response.data.logged_in) {
                window.location.href = '/';
            }
        } catch (error) {
            console.error("Erro ao verificar o link do dispositivo:", error);
        }
    }, 300);

    try {
        Echo.private(`device-status.${deviceFingerprintId}`)
            .listen('DeviceStatusChangedEvent', async (e) => {
                console.log("Device Status Changed: ", e);


            });
    } catch (error) {
        console.error("Erro ao configurar Echo para 'device-status' channel:", error);
    }

    try {
        const localDeviceIp = await fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => data.ip);

        await registerFingerprint(deviceFingerprintId, localDeviceIp);
    } catch (error) {
        console.error("Erro ao registrar fingerprint ou obter IP:", error);
    }

    // Ajuste para suportar login com senha e login por código
    handleFingerprintInForms(deviceFingerprintId);

    try {
        Echo.channel(`device-logged.${deviceFingerprintId}`)
            .listen('DeviceLoggedEvent', (e) => {
                console.log("Device logged: ", e);

                axios.post('/api/authenticate-with-token', {
                    token: e.temporary_token,
                })
                    .then(response => {
                        if (response.data.authenticated) {
                            window.location.href = '/';
                        } else {
                            console.error('Falha ao autenticar o dispositivo.');
                        }
                    })
                    .catch(error => {
                        console.error('Erro ao autenticar o dispositivo:', error);
                    });
            });
    } catch (error) {
        console.error("Erro ao configurar Echo para 'device-logged' channel:", error);
    }
});

function handleFingerprintInForms(deviceFingerprintId) {
    const loginForm = document.getElementById('login-form');
    const verificationForm = document.getElementById('verification-form');
    const formMail = document.getElementById('form-mail');

    if (loginForm) {
        attachFingerprintToForm(loginForm, deviceFingerprintId);
    }

    if (verificationForm) {
        attachFingerprintToForm(verificationForm, deviceFingerprintId);
    }

    if (formMail) {
        attachFingerprintToForm(formMail, deviceFingerprintId);
    }
}

function attachFingerprintToForm(form, fingerprint) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'device_fingerprint_id';
    input.value = fingerprint;
    form.appendChild(input);
}

async function initializeFingerprintAndQRCode() {
    try {
        const visitorId = await generateFingerprint();

        updateQRCode(visitorId);
        displayLoginCode(visitorId);

        return visitorId;
    } catch (error) {
        console.error("Erro ao gerar o fingerprint ou o QRCode: ", error);
    }
}

async function generateFingerprint() {
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const result = await fp.get();

    return result.visitorId;
}

function updateQRCode(visitorId) {
    const qrcodeElement = document.getElementById("qrcodeCanvas");
    if (!qrcodeElement) return;

    const url = `${window.location.origin}/vid/${visitorId}`;
    QRCode.toCanvas(qrcodeElement, url, {
        width: 150,
        height: 150,
    });
}

function displayLoginCode(visitorId) {
    const lastSix = visitorId.slice(-6);
    const visitorIdElements = document.querySelectorAll(".visitorId > div");

    if (visitorIdElements.length !== 7) {
        console.error("Os elementos de exibição do código de login não estão completos.");
        return;
    }

    visitorIdElements.forEach((element, index) => {
        if (index !== 3) {
            element.textContent = lastSix[index > 3 ? index - 1 : index];
        }
    });
}

async function registerFingerprint(fingerprint, localIp) {
    try {
        const response = await axios.post('/register-fingerprint', {
            device_fingerprint_id: fingerprint,
            device_ip: localIp,
        });

    } catch (error) {
        console.error('Erro ao registrar o fingerprint:', error);
    }
}
